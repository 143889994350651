import Component from '../../util/Component';

export default class extends Component {
  unimpersonate = () => {
    this.$.Admin.unimpersonate({ userUuid: this.$.currentUser.uuid });
  };

  render() {
    if (!this.$.user || !this.$.user.impersonating) {
      return null;
    }
    const styles = {
      banner: {
        background:
          'linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3)',
        color: '#fff',
      },
    };
    return (
      <div
        data-test="impersonation-banner"
        className="p-2 c-hand text-center form-label rainbow"
        style={styles.banner}
        onClick={this.unimpersonate}
      >
        Return to Account
      </div>
    );
  }
}
