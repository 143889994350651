import React from 'react';
import Observables from '../stores/Observables';
import PropTypes from 'prop-types';
import config from '../config';
import { toJS } from 'mobx';

if (config.env === 'development') {
  global.toJS = toJS;
}

export default class extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };
  constructor(props) {
    super(props);
    this.$ = Observables.get();
  }

  getComponent(key, includeParent = true) {
    let children = null;
    if (Array.isArray(this.props.children)) {
      children = this.props.children.filter((c) => c && c.key === key);
    } else {
      children = this.props.children.key === key ? [this.props.children] : [];
    }
    return children[0] && !includeParent
      ? children[0].props.children
      : children;
  }
}
