import config from '../config';
import fetch from './Fetch';
import Observables from '../stores/Observables';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = () => {
  // do not remove - can be called from server
  if (typeof window === 'undefined') return;

  const page_path = window.location.pathname.replace(
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi,
    '__uuid__'
  );
  const page_location = window.location.href.replace(
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi,
    '__uuid__'
  );
  const page_title = window.document.title;
  const event = {
    page_location,
    page_path,
    page_title,
  };
  if (window.gtag) {
    window.gtag('config', config.gtmId, event);
  }
  if (window.ga) {
    window.ga('send', 'pageview', page_path);
  }

  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['trackPageView']);
};

export const charge = (plan, period, price, source, quantity = 1) => {
  // do not remove - can be called from server
  if (typeof window === 'undefined') return;

  const txid = new Date().getTime().toString();
  if (window.ga) {
    window.ga('ecommerce:addTransaction', {
      id: txid,
      affiliation: source,
      revenue: price,
      shipping: '0',
      tax: '0',
      currency: 'USD',
    });
    window.ga('ecommerce:addItem', {
      id: txid,
      name: `${plan === 'pro' ? 'Pro' : 'Team'} ${period}`,
      sku: `${plan}-${period}`,
      price,
      quantity,
    });
    window.ga('ecommerce:send');
  }

  if (window.fbq) {
    window.fbq('track', 'Purchase', {
      value: price,
      currency: 'USD',
      content_name: `${plan === 'pro' ? 'Pro' : 'Team'} ${period}`,
      content_ids: [`${plan}-${period}`],
    });
  }

  fetch(`${config.apiHost}/api/v1/me/track`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Observables.get().authToken}`,
    },
    json: {
      type: `paywall-${source}-purchase`,
    },
    redirectUnauthenticated: false,
  }).catch(() => {});
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  // do not remove - can be called from server
  if (typeof window === 'undefined') return;

  const event = {
    event_category: category,
    event_label: label,
    value: value,
  };
  if (window.gtag) {
    window.gtag('event', action, event);
  }
};
