import Component from '../../util/Component';
import PropTypes from 'prop-types';
import config from '../../config';

export default class Userpilot extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    this.updateUserpilot();
  }

  componentDidUpdate() {
    this.updateUserpilot();
  }

  isUserpilotInstalled = () => {
    return window && window.userpilot;
  };

  shouldSaveUser = () => {
    const { user } = this.props;
    return user && user.uuid && !user.impersonating;
  };

  updateUserpilot() {
    this.url = window.location.href;
    if (this.isUserpilotInstalled()) {
      window.userpilotSettings = {
        token: config.userpilot.token,
      };
      if (this.shouldSaveUser()) {
        const { user } = this.props;
        try {
          window.userpilot.identify(user.uuid, {
            created_at: user.createdAt,
            name: user.name,
            email: user.email,
            phone: user.phone_number,
            location: user.qualifiedRegion,
            profile_photo: user.profileImageUri,
            company_name: user.company_name,
            plan_type: user.billing.billing_plan,
            billing_expires: user.billing.billing_expires,
          });
        } catch (e) {
          console.log(e);
          throw e; // intentionally re-throw (caught by window.onerror)
        }
      } else {
        try {
          window.userpilot.anonymous();
        } catch (e) {
          console.log(e);
          throw e; // intentionally re-throw (caught by window.onerror)
        }
      }
    }
  }

  render() {
    return false;
  }
}
