import Component from '../../util/Component';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import { get } from 'lodash';

@observer
export default class HomelightAnnouncement extends Component {
  static propTypes = {
    user: PropTypes.object,
    grid: PropTypes.string,
  };

  static defaultProps = {
    grid: 'grid-2x',
  };

  constructor(props) {
    super(props);
    this.state = {
      dismissed: get(this.props.user, 'settings.hasDismissedHLM', false),
    };
  }

  onClickDismiss = async () => {
    await this.$.User.updateSettings({
      hasDismissedHLM: true,
    });
    this.setState({
      dismissed: true,
    });
  };

  render() {
    // show announcement to users for two weeks after launch
    if (
      !this.props.user ||
      !this.props.user.uuid ||
      this.state.dismissed ||
      moment() > moment('2021-05-01')
    ) {
      return null;
    }
    return (
      <div className="bg-secondary p-2 form-label c-hand">
        <div className={`container text-center ${this.props.grid}`}>
          <a className="float-right px-1" onClick={this.onClickDismiss}>
            <i className="icon icon-cross text-default" />
          </a>
          <a
            onClick={this.onClickDismiss}
            href="https://academy.disclosures.io/en/hlm"
            target="_blank"
            rel="noopener noreferrer"
            className="d-inline-block text-default"
          >
            Disclosures.io has been renamed to HomeLight Listing Management -
            Learn More!
          </a>
        </div>
      </div>
    );
  }
}
