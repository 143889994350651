import Component from '../..//util/Component';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    toast: PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      type: PropTypes.string,
      linkText: PropTypes.string,
      linkHref: PropTypes.string,
    }),
  };

  dismiss = () => {
    this.$.ToastList.removeToast(this.props.toast.id);
  };

  render() {
    return (
      <div
        data-test="toast"
        id={`toast-${this.props.toast.id}`}
        className={`toast toast-${this.props.toast.type || 'success'} my-4`}
      >
        <div className="container grid-md mt-4 mb-4">
          <button
            data-test="toast-dismiss"
            className="btn btn-clear float-right"
            onClick={this.dismiss}
          />
          <span data-test="toast-message">{this.props.toast.message}</span>
          {this.props.toast.linkText && (
            <span>
              {' '}
              <a href={this.props.toast.linkHref}>
                {this.props.toast.linkText}
              </a>
            </span>
          )}
        </div>
      </div>
    );
  }
}
