import Component from '../../util/Component';
import PropTypes from 'prop-types';
import { parseFullName } from 'parse-full-name';
import config from '../../config';

export default class extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    this.installHubspot();

    const checkHubspot = setInterval(() => {
      if (this.isHubspotInstalled()) {
        clearInterval(checkHubspot);
        this.updateHubspot();
      }
    }, 1000);
  }

  componentDidUpdate() {
    this.updateHubspot();
  }

  updateHubspot() {
    if (this.isHubspotInstalled() && this.shouldSaveUser()) {
      const data = {};
      const { user } = this.props;
      const fullName = parseFullName(user.name);
      data.firstname = fullName.first;
      data.lastname = fullName.last;
      data.email = user.email;
      if (user.uuid) data.has_account = true;
      if (user.company_name) data.company = user.company_name;
      if (user.phone_number) data.phone = user.phone_number;

      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['identify', data]);
    }
  }

  installHubspot() {
    if (config.hubspot && config.hubspot.accountId) {
      if (document.getElementById('hs-script-loader')) return;

      const firstJs = document.getElementsByTagName('script')[0];
      const hubspotConfig = document.createElement('script');
      hubspotConfig.textContent = `window.hsConversationsSettings = {
        loadImmediately: true,
      }`;
      firstJs.parentNode.appendChild(hubspotConfig);
      const hubspotLoader = document.createElement('script');
      hubspotLoader.id = 'hs-script-loader';
      hubspotLoader.async = true;
      hubspotLoader.defer = true;
      hubspotLoader.src = `//js.hs-scripts.com/${config.hubspot.accountId}.js`;
      firstJs.parentNode.appendChild(hubspotLoader);
    }
  }

  isHubspotInstalled() {
    return window && window.HubSpotConversations;
  }

  shouldSaveUser() {
    const { user } = this.props;
    return user && user.email && !user.doNotSave && !user.impersonating;
  }

  render() {
    return false;
  }
}
