import Component from '../../util/Component';
import PropTypes from 'prop-types';
import { startsWith } from 'lodash';
import moment from 'moment';
import config from '../../config';

export default class Wootric extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    this.updateWootric();
  }

  componentDidUpdate() {
    this.updateWootric();
  }

  updateWootric() {
    if (this.isWootricInstalled() && this.shouldSaveUser()) {
      const wootricBrokerage = config.wootric.brokerage;
      const brokerage = (this.$.user.brokerages || []).find((b) =>
        startsWith(b.name, wootricBrokerage)
      );
      if (brokerage && brokerage.offices.length > 0) {
        const office = brokerage.offices[0];
        try {
          window.wootricSettings = {
            email: this.props.user.email,
            external_id: this.props.user.uuid,
            created_at: moment(brokerage.activeAt).unix(),
            product_name: 'HomeLight Listing Management',
            account_token: 'NPS-a2cd40c8',
            properties: {
              product_name: 'HomeLight Listing Management',
              account_creation_date: moment(office.activeAt).unix(),
              company: wootricBrokerage,
              office_name: office.name,
              office_ID: office.importId,
              okta_ID: this.props.user.importId,
              metro_ID: brokerage.metroName,
            },
          };
          window.wootric('run');
        } catch (e) {
          console.log(e);
          throw e; // intentionally re-throw (caught by window.onerror)
        }
      }
    }
  }

  isWootricInstalled = () => {
    return window && window.wootric;
  };

  shouldSaveUser = () => {
    const { user } = this.props;
    return user && user.uuid && !user.impersonating;
  };

  render() {
    return false;
  }
}
