import Head from 'next/head';
import config from '../../config';

export default function AppHead() {
  return (
    <Head>
      <title>HomeLight Listing Management</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link
        href={`${config.disco.host}${config.disco.path}/${config.disco['disco.min.css']}`}
        rel="stylesheet"
        type="text/css"
      />
      <link
        href={`${config.disco.host}${config.disco.path}/${config.disco['disco-exp.min.css']}`}
        rel="stylesheet"
        type="text/css"
      />
      <link
        href={`${config.disco.host}${config.disco.path}/${config.disco['disco-icons.min.css']}`}
        rel="stylesheet"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"
        integrity="sha384-DNOHZ68U8hZfKXOrtjWvjxusGo9WQnrNx2sqG0tfsghAvtVlRW3tvkXWZh58N9jp"
        crossOrigin="anonymous"
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="shortcut icon"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <link
        rel="icon"
        href={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-TileImage"
        content={`${config.cdnHost}${config.cdnPath}/${config.favicon}`}
      />
      <script
        crossOrigin="anonymous"
        type="text/javascript"
        src="https://www.dropbox.com/static/api/2/dropins.js"
        id="dropboxjs"
        data-app-key={config.dropbox.appKey}
      />
      <script
        type="text/javascript"
        src="https://apis.google.com/js/api.js?onload=loadPicker"
      />
      {process.browser && config.gtmId && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.gtmId}');
            `,
          }}
        />
      )}
      {process.browser && config.gaId && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
            ga('create', '${config.gaId}', 'auto');
            ga('require', 'linkid');
            ga('require', 'ecommerce');
            `,
            }}
          />
          <script
            async
            src="https://www.google-analytics.com/analytics.js"
          ></script>
        </>
      )}
      {process.browser && config.customerio && config.customerio.siteId && (
        // Manually load customer io script due to NextJS bug
        // https://github.com/zeit/next.js/issues/8921
        <>
          <script
            id="cio-tracker"
            async
            data-site-id={config.customerio.siteId}
            src="https://assets.customer.io/assets/track.js"
          ></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                var _cio = _cio || [];

                (function() {
                  var a,b,c;a=function(f){return function(){_cio.push([f].
                  concat(Array.prototype.slice.call(arguments,0)))}};b=["identify",
                  "track"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
                })();
                `,
            }}
          />
        </>
      )}
      {/* Wootric script for Coldwell Banker */}
      {config.wootric && config.wootric.brokerage && (
        <script
          type="text/javascript"
          src="https://cdn.wootric.com/wootric-sdk.js"
        />
      )}
      {/* Manually load script for Userpilot */}
      {config.userpilot && (
        <script
          type="text/javascript"
          src="https://js.userpilot.io/sdk/latest.js"
        />
      )}
    </Head>
  );
}
