import { observer } from 'mobx-react';
import moment from 'moment';
import { withRouter } from 'next/router';
import Component from '../../util/Component';
import { PACKAGE_ATTACHMENT_REQUEST_TYPES } from '../../util/Constants';

@withRouter
@observer
export default class extends Component {
  handleOpenBanner = () => {
    this.$.ModalList.pushModal(this.$.ModalList.names.packageTradeIn);
  };

  handleDismissBanner = async () => {
    await this.$.User.updateSettings({
      hasDismissedTradeIn: moment().format('YYYY-MM-DD'),
    });
    await this.$.PackageContributor.dismissAttachment(
      this.$.packageActiveContributor.uuid,
      PACKAGE_ATTACHMENT_REQUEST_TYPES.HL_TRADE_IN_DISMISSAL
    );
  };

  render() {
    const tradeIn =
      this.$.packageActiveContributor &&
      this.$.packageActiveContributor.attachments &&
      this.$.packageActiveContributor.attachments.tradeIn;

    const acknowledged = tradeIn
      ? tradeIn.state === 'requested' || tradeIn.state === 'dismissed'
      : false;

    const role =
      this.$.packageActiveContributor && this.$.packageActiveContributor.role;

    const pathMatch =
      this.props.router.route.match(/\/app\/packages\/detail/) &&
      !this.props.router.query.documentUuid;

    const attachmentState = (tradeIn && tradeIn.state) || 'notstarted';

    const tradeInRequested = this.$.currentUser?.settings?.hasRequestedTradeIn;

    const isRedfin =
      this.$.packageActiveContributor &&
      this.$.packageActiveContributor.email?.match(/redfin\.com/i);

    const tradeInAvailableStates = ['CA', 'TX', 'FL', 'CO'];
    const tradeInAvailable =
      this.$.packageDetail &&
      tradeInAvailableStates.includes(this.$.packageDetail.property_state);

    if (
      pathMatch &&
      role === 'Admin' &&
      !acknowledged &&
      !isRedfin &&
      attachmentState !== 'dismissed' &&
      !tradeInRequested &&
      tradeInAvailable
    ) {
      return (
        <div data-test="trade-in-banner" className="text-center bg-dark py-2">
          <div className="container grid-xl">
            <span
              className="float-right px-1 c-hand"
              onClick={this.handleDismissBanner}
            >
              <i className="icon icon-cross text-white" />
            </span>
            <span
              className="mb-0 mr-4 c-hand p-4"
              onClick={this.handleOpenBanner}
            >
              Your clients can <b>buy before they sell</b> and still get full
              market value. Click here to learn more.
            </span>
          </div>
        </div>
      );
    }

    return null;
  }
}
