import { observer } from 'mobx-react';
import { withRouter } from 'next/router';
import Component from '../../util/Component';

@withRouter
@observer
export default class extends Component {
  handleOpenBanner = () => {
    this.$.ModalList.pushModal(this.$.ModalList.names.packageCashOffer);
  };

  render() {
    const cashOffer =
      this.$.packageActiveContributor &&
      this.$.packageActiveContributor.attachments &&
      this.$.packageActiveContributor.attachments.cashOffer;

    const acknowledged = cashOffer
      ? cashOffer.state === 'requested' || cashOffer.state === 'dismissed'
      : false;

    const role =
      this.$.packageActiveContributor && this.$.packageActiveContributor.role;

    const pathMatch = this.props.router.route.match(/\/app\/packages\/detail/);

    const attachmentState = (cashOffer && cashOffer.state) || 'notstarted';

    const isRedfin =
      this.$.packageActiveContributor &&
      this.$.packageActiveContributor.email?.match(/redfin\.com/i);

    const cashOfferAvailableStates = ['CA', 'TX', 'FL', 'CO'];
    const cashOfferAvailable =
      this.$.packageDetail &&
      cashOfferAvailableStates.includes(this.$.packageDetail.property_state);

    if (
      pathMatch &&
      role === 'Buyer Agent' &&
      !acknowledged &&
      !isRedfin &&
      this.$.currentUser.confirmed &&
      attachmentState !== 'dismissed' &&
      cashOfferAvailable
    ) {
      return (
        <div
          data-test="cash-offer-banner"
          className="p-2 c-hand text-center d-flex bg-dark column col-12"
          style={{ justifyContent: 'center', alignItems: 'center' }}
          onClick={this.handleOpenBanner}
        >
          <p className="mr-4 mb-0">
            Enable your clients to write a <b>cash offer</b> on this property.
            Click here to learn more.
          </p>
        </div>
      );
    }

    return null;
  }
}
