import React, { Component } from 'react';

export default class extends Component {
  state = {
    offline: false,
  };

  componentDidMount() {
    window.addEventListener('offline', this.onOffline);
    window.addEventListener('online', this.onOnline);
  }
  componentWillUnmount() {
    window.removeEventListener('online', this.onOnline);
    window.removeEventListener('offline', this.onOffline);
  }

  onOffline = () => {
    this.setState({ offline: true });
  };

  onOnline = () => {
    this.setState({ offline: false });
  };

  render() {
    return (
      <>
        {this.state.offline ? (
          <div
            data-test="offline-banner"
            className="p-2 text-center form-label bg-warning"
          >
            No internet connection!
          </div>
        ) : null}
      </>
    );
  }
}
