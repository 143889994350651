import Component from '../../util/Component';
import PropTypes from 'prop-types';

export default class CustomerIO extends Component {
  static propTypes = {
    user: PropTypes.object,
    data: PropTypes.object,
  };

  url = null;

  componentDidMount() {
    this.updateCustomerIO();
  }

  componentDidUpdate() {
    this.updateCustomerIO();
  }

  shouldComponentUpdate = () => {
    // only update if url changed
    return this.url !== window.location.href;
  };

  updateCustomerIO() {
    this.url = window.location.href;
    if (this.isCIOInstalled()) {
      if (this.shouldSaveUser()) {
        const { user } = this.props;
        try {
          const identity = {
            id: user.hash,
            name: user.name,
            email: user.email,
            ...this.props.data,
          };
          window._cio.identify(identity);
        } catch (e) {
          console.log(e);
          // throw e; // intentionally re-throw (caught by window.onerror)
        }
      }

      const pageView = {};
      if (document.referrer && '' != document.referrer) {
        pageView.referrer = document.referrer;
      }
      window._cio.page(this.url, pageView);
    }
  }

  isCIOInstalled() {
    return !!(
      window &&
      window._cio &&
      window._cio.identify &&
      window._cio.page
    );
  }

  shouldSaveUser() {
    const { user } = this.props;
    return user && user.hash && !user.doNotSave && !user.impersonating;
  }

  render() {
    return false;
  }
}
