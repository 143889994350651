import Component from '../../util/Component';
import ImpersonationBanner from './ImpersonationBanner';
import HomelightAnnouncement from './HomelightAnnouncement';
import OfflineBanner from './OfflineBanner';
import Head from './Head';
import Toasts from './Toasts';
import Hubspot from './Hubspot';
import CustomerIO from './CustomerIO';
import Wootric from './Wootric';
import Userpilot from './Userpilot';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import Router from 'next/router';
import * as Analytics from '../../util/Analytics';
import TouchBackend from 'react-dnd-touch-backend';
import { BROADCAST_KEY } from '../../util/Constants';
import { DragDropContext } from 'react-dnd';
import { withRouter } from 'next/router';
import config from '../../config';
import fetch from '../../util/Fetch';
import CashOfferBanner from './CashOfferBanner';
import TradeInBanner from './TradeInBanner';

// once for initial load
Analytics.pageview();

// for subsequent page views
Router.events.on('routeChangeComplete', () => {
  Analytics.pageview();
});

@DragDropContext(TouchBackend({ enableMouseEvents: true }))
class Page extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    children: PropTypes.node,
    grid: PropTypes.string,
  };

  static defaultProps = {
    grid: 'grid-2x',
  };

  constructor(props) {
    super(props);
    this.reloadIntervalId = null;
  }

  componentDidMount() {
    NProgress.configure({ showSpinner: false });
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());

    // potentially reload page once an hour
    this.reloadIntervalId = setInterval(this.reloadPage, 3600000);

    // broadcast session state to other tabs
    if (this.props.router.pathname.startsWith('/app/')) {
      try {
        window.localStorage?.setItem(
          BROADCAST_KEY,
          JSON.stringify({
            email: this.$.user ? this.$.user.email : '',
            provider: this.$.user ? this.$.user.provider : '',
            packageUuid: this.props.router.query.packageUuid,
          })
        );

        window.localStorage?.removeItem(BROADCAST_KEY);
      } catch (e) {
        console.error(e);
      }
    }

    // add listener to localstorage for cross tab/window communication
    window.addEventListener('storage', this.checkUser);

    // throw user agent into the body object of each page for browser css targeting
    document.documentElement.setAttribute('data-agent', navigator.userAgent);
  }

  componentWillUnmount() {
    clearInterval(this.reloadIntervalId);
    window.removeEventListener('storage', this.checkUser);
  }

  reloadPage = async () => {
    const rsp = await fetch(`${config.appHost}/sha`, {
      method: 'GET',
    });
    if (rsp.ok) {
      const body = await rsp.json();

      if (body.commitSha !== config.commitSha) {
        window.location.reload(true);
      }
    }
  };

  checkUser = (e) => {
    let json = null;
    try {
      json = JSON.parse(e.newValue);
      if (e.key !== BROADCAST_KEY || !json) throw new Error('Skip');
    } catch (err) {
      return;
    }

    // reload page to pick up new user
    if (
      !this.$.user ||
      (this.$.user.provider === 'package' &&
        !this.props.router.query.packageUuid)
    ) {
      window.location = `/auth/login${
        json.email ? '?return_uri_email=' + json.email : ''
      }`;
    }
    // otherwise, check if emails don't match or package changed
    else if (
      json.email !== this.$.user.email ||
      (json.provider === 'package' &&
        json.packageUuid !== this.props.router.query.packageUuid)
    ) {
      window.location = `/auth/login?return_uri_email=${this.$.user.email}&return_uri=${this.props.router.asPath}`;
    }
  };

  render() {
    return (
      <div data-test="root" className="bg-gray" style={{ height: '100%' }}>
        <Head />
        <Toasts />
        <Hubspot user={this.$.user} />
        <CustomerIO user={this.$.user} />
        <Wootric user={this.$.user} />
        <Userpilot user={this.$.user} />
        <HomelightAnnouncement user={this.$.user} grid={this.props.grid} />
        <ImpersonationBanner />
        {config.cashOfferPromotion && <CashOfferBanner />}
        {config.tradeInPromotion && <TradeInBanner />}
        <OfflineBanner />

        <div
          className={`container ${this.props.grid} p-0 border-left border-right`}
          style={{ background: 'white', minHeight: '100vh' }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default withRouter(Page);
